exports.components = {
  "component---src-pages-2-d-density-plot-js": () => import("./../../../src/pages/2d-density-plot.js" /* webpackChunkName: "component---src-pages-2-d-density-plot-js" */),
  "component---src-pages-3-d-js": () => import("./../../../src/pages/3d.js" /* webpackChunkName: "component---src-pages-3-d-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-charts-js": () => import("./../../../src/pages/all-charts.js" /* webpackChunkName: "component---src-pages-all-charts-js" */),
  "component---src-pages-animation-js": () => import("./../../../src/pages/animation.js" /* webpackChunkName: "component---src-pages-animation-js" */),
  "component---src-pages-arc-diagram-js": () => import("./../../../src/pages/arc-diagram.js" /* webpackChunkName: "component---src-pages-arc-diagram-js" */),
  "component---src-pages-area-plot-js": () => import("./../../../src/pages/area-plot.js" /* webpackChunkName: "component---src-pages-area-plot-js" */),
  "component---src-pages-barplot-js": () => import("./../../../src/pages/barplot.js" /* webpackChunkName: "component---src-pages-barplot-js" */),
  "component---src-pages-beeswarm-js": () => import("./../../../src/pages/beeswarm.js" /* webpackChunkName: "component---src-pages-beeswarm-js" */),
  "component---src-pages-best-dataviz-packages-js": () => import("./../../../src/pages/best-dataviz-packages.js" /* webpackChunkName: "component---src-pages-best-dataviz-packages-js" */),
  "component---src-pages-best-python-chart-examples-js": () => import("./../../../src/pages/best-python-chart-examples.js" /* webpackChunkName: "component---src-pages-best-python-chart-examples-js" */),
  "component---src-pages-boxplot-js": () => import("./../../../src/pages/boxplot.js" /* webpackChunkName: "component---src-pages-boxplot-js" */),
  "component---src-pages-bubble-map-js": () => import("./../../../src/pages/bubble-map.js" /* webpackChunkName: "component---src-pages-bubble-map-js" */),
  "component---src-pages-bubble-plot-js": () => import("./../../../src/pages/bubble-plot.js" /* webpackChunkName: "component---src-pages-bubble-plot-js" */),
  "component---src-pages-candlestick-js": () => import("./../../../src/pages/candlestick.js" /* webpackChunkName: "component---src-pages-candlestick-js" */),
  "component---src-pages-cartogram-js": () => import("./../../../src/pages/cartogram.js" /* webpackChunkName: "component---src-pages-cartogram-js" */),
  "component---src-pages-cheat-sheets-js": () => import("./../../../src/pages/cheat-sheets.js" /* webpackChunkName: "component---src-pages-cheat-sheets-js" */),
  "component---src-pages-chord-diagram-js": () => import("./../../../src/pages/chord-diagram.js" /* webpackChunkName: "component---src-pages-chord-diagram-js" */),
  "component---src-pages-choropleth-map-js": () => import("./../../../src/pages/choropleth-map.js" /* webpackChunkName: "component---src-pages-choropleth-map-js" */),
  "component---src-pages-circular-barplot-js": () => import("./../../../src/pages/circular-barplot.js" /* webpackChunkName: "component---src-pages-circular-barplot-js" */),
  "component---src-pages-circular-packing-js": () => import("./../../../src/pages/circular-packing.js" /* webpackChunkName: "component---src-pages-circular-packing-js" */),
  "component---src-pages-color-palette-finder-js": () => import("./../../../src/pages/color-palette-finder.js" /* webpackChunkName: "component---src-pages-color-palette-finder-js" */),
  "component---src-pages-connected-scatter-plot-js": () => import("./../../../src/pages/connected-scatter-plot.js" /* webpackChunkName: "component---src-pages-connected-scatter-plot-js" */),
  "component---src-pages-connection-map-js": () => import("./../../../src/pages/connection-map.js" /* webpackChunkName: "component---src-pages-connection-map-js" */),
  "component---src-pages-correlogram-js": () => import("./../../../src/pages/correlogram.js" /* webpackChunkName: "component---src-pages-correlogram-js" */),
  "component---src-pages-dendrogram-js": () => import("./../../../src/pages/dendrogram.js" /* webpackChunkName: "component---src-pages-dendrogram-js" */),
  "component---src-pages-density-plot-js": () => import("./../../../src/pages/density-plot.js" /* webpackChunkName: "component---src-pages-density-plot-js" */),
  "component---src-pages-donut-plot-js": () => import("./../../../src/pages/donut-plot.js" /* webpackChunkName: "component---src-pages-donut-plot-js" */),
  "component---src-pages-drawarrow-js": () => import("./../../../src/pages/drawarrow.js" /* webpackChunkName: "component---src-pages-drawarrow-js" */),
  "component---src-pages-heatmap-js": () => import("./../../../src/pages/heatmap.js" /* webpackChunkName: "component---src-pages-heatmap-js" */),
  "component---src-pages-hexbin-map-js": () => import("./../../../src/pages/hexbin-map.js" /* webpackChunkName: "component---src-pages-hexbin-map-js" */),
  "component---src-pages-hierarchical-edge-bundling-js": () => import("./../../../src/pages/hierarchical-edge-bundling.js" /* webpackChunkName: "component---src-pages-hierarchical-edge-bundling-js" */),
  "component---src-pages-histogram-js": () => import("./../../../src/pages/histogram.js" /* webpackChunkName: "component---src-pages-histogram-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jupyter-notebook-slug-js": () => import("./../../../src/pages/{jupyterNotebook.slug}.js" /* webpackChunkName: "component---src-pages-jupyter-notebook-slug-js" */),
  "component---src-pages-line-chart-js": () => import("./../../../src/pages/line-chart.js" /* webpackChunkName: "component---src-pages-line-chart-js" */),
  "component---src-pages-lollipop-plot-js": () => import("./../../../src/pages/lollipop-plot.js" /* webpackChunkName: "component---src-pages-lollipop-plot-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-matplotlib-js": () => import("./../../../src/pages/matplotlib.js" /* webpackChunkName: "component---src-pages-matplotlib-js" */),
  "component---src-pages-network-chart-js": () => import("./../../../src/pages/network-chart.js" /* webpackChunkName: "component---src-pages-network-chart-js" */),
  "component---src-pages-pandas-js": () => import("./../../../src/pages/pandas.js" /* webpackChunkName: "component---src-pages-pandas-js" */),
  "component---src-pages-parallel-plot-js": () => import("./../../../src/pages/parallel-plot.js" /* webpackChunkName: "component---src-pages-parallel-plot-js" */),
  "component---src-pages-pie-plot-js": () => import("./../../../src/pages/pie-plot.js" /* webpackChunkName: "component---src-pages-pie-plot-js" */),
  "component---src-pages-plotly-js": () => import("./../../../src/pages/plotly.js" /* webpackChunkName: "component---src-pages-plotly-js" */),
  "component---src-pages-plotnine-js": () => import("./../../../src/pages/plotnine.js" /* webpackChunkName: "component---src-pages-plotnine-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-python-colors-js": () => import("./../../../src/pages/python-colors.js" /* webpackChunkName: "component---src-pages-python-colors-js" */),
  "component---src-pages-radar-chart-js": () => import("./../../../src/pages/radar-chart.js" /* webpackChunkName: "component---src-pages-radar-chart-js" */),
  "component---src-pages-ridgeline-js": () => import("./../../../src/pages/ridgeline.js" /* webpackChunkName: "component---src-pages-ridgeline-js" */),
  "component---src-pages-sankey-diagram-js": () => import("./../../../src/pages/sankey-diagram.js" /* webpackChunkName: "component---src-pages-sankey-diagram-js" */),
  "component---src-pages-scatter-plot-js": () => import("./../../../src/pages/scatter-plot.js" /* webpackChunkName: "component---src-pages-scatter-plot-js" */),
  "component---src-pages-seaborn-js": () => import("./../../../src/pages/seaborn.js" /* webpackChunkName: "component---src-pages-seaborn-js" */),
  "component---src-pages-stacked-area-plot-js": () => import("./../../../src/pages/stacked-area-plot.js" /* webpackChunkName: "component---src-pages-stacked-area-plot-js" */),
  "component---src-pages-statistics-js": () => import("./../../../src/pages/statistics.js" /* webpackChunkName: "component---src-pages-statistics-js" */),
  "component---src-pages-streamchart-js": () => import("./../../../src/pages/streamchart.js" /* webpackChunkName: "component---src-pages-streamchart-js" */),
  "component---src-pages-table-js": () => import("./../../../src/pages/table.js" /* webpackChunkName: "component---src-pages-table-js" */),
  "component---src-pages-timeseries-js": () => import("./../../../src/pages/timeseries.js" /* webpackChunkName: "component---src-pages-timeseries-js" */),
  "component---src-pages-treemap-js": () => import("./../../../src/pages/treemap.js" /* webpackChunkName: "component---src-pages-treemap-js" */),
  "component---src-pages-venn-diagram-js": () => import("./../../../src/pages/venn-diagram.js" /* webpackChunkName: "component---src-pages-venn-diagram-js" */),
  "component---src-pages-violin-plot-js": () => import("./../../../src/pages/violin-plot.js" /* webpackChunkName: "component---src-pages-violin-plot-js" */),
  "component---src-pages-waffle-chart-js": () => import("./../../../src/pages/waffle-chart.js" /* webpackChunkName: "component---src-pages-waffle-chart-js" */),
  "component---src-pages-wordcloud-js": () => import("./../../../src/pages/wordcloud.js" /* webpackChunkName: "component---src-pages-wordcloud-js" */)
}

